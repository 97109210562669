<template>
  <transition-group name="fade" class="card-wrap" tag="div" appear>
    <router-link v-for="film in filmsSort" :key="film.id" :to="`/watch/${film.id}/${film.last_watch_video || 1}`"
      class="card" :style="`background: url(${film.img}) 50% 50% / cover;`">
      <div class="title">{{ film.name }}</div>
      <div class="last_added_video right">({{ film.full_count_video }}/{{
        getLastAdd(film) }})</div>
      <div v-if="film?.raiting" :class="`raiting ${raiting_class(film?.raiting)}`">
        <Icon :name="`star`" /> {{ film?.raiting }}
      </div>

      <div v-if="!newVideo" class="last_added_video" :class="{ new: getNoWatchCount(film) }">
        <Icon v-if="!getNoWatchCount(film)" :name="`eye`" />
        <span v-else>{{ getNoWatchCount(film) }}</span>

      </div>
    </router-link>
  </transition-group>
</template>

<script>
import Icon from './Icon.vue';

export default {
  methods: {
    raiting_class(raiting) {
      let res = 'bronz'

      if (raiting > 9)
        res = "gold"

      else if (raiting > 8)
        res = "silver"

      return res;
    },
    normolizeVideoTitle(value = "") {
      const regex = /[0-9]+$/gm;
      let res = regex.exec(value);
      return res?.length ? res[0] : 0;
    },
    getLastWatch(film) {
      if (film?.series) {
        let title = film.series[film.last_watch_video]?.title;
        if (title)
          return title || 0;
        else {
          let res;
          for (const [key, value] of Object.entries(film.series)) {
            if (value.id == this.$route.params.seria_id) {
              res = value.title;
            }
          }
          return this.normolizeVideoTitle(res);
        }
      } else
        return this.normolizeVideoTitle(film.last_watch_video || 0);
    },
    getLastAdd(film) {
      if (film?.series) {
        return this.normolizeVideoTitle(film?.series[film.last_added_video]?.title);
      } else
        return this.normolizeVideoTitle(film.last_added_video);
    },
    getNoWatchCount(film) {
      let lastAdd = this.getLastAdd(film);
      let lastWatch = this.getLastWatch(film);

      let noWatchCount = +lastAdd - +lastWatch;

      return noWatchCount > 0 ? `+${noWatchCount}` : 0;
    },
  },
  props: {
    filmsSort: {
      type: Array,
      default: [],
    },
    newVideo: {
      type: Boolean,
      default: false
    }
  },
  components: { Icon },
};
</script>

<style lang="scss">
.card-wrap {
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: calc((100% - 120px) / 6);
  height: 180px;
  border-radius: 15px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin: 10px;
  position: relative;
  overflow: hidden;
  transition: 0.3s;

  @media (max-width: 1023px) {
    width: calc(33.3% - 10px);
    margin: 5px;
  }

  @media (max-width: 500px) {
    width: calc(50% - 10px);
    margin: 5px;
  }

  &:hover {
    // transform: scale(1.1);
    box-shadow: 0 0 15px rgb(0 0 0);
    margin-top: -50px;
    margin-bottom: -50px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc((100% - 120px) / 6 + 20px);
    height: 280px;
    z-index: 1;

    @media (max-width: 1023px) {
      width: calc(33.3% - -4px);
      margin-top: -50px;
      margin-bottom: -50px;
      margin-left: -2px;
      margin-right: -2px;
    }

    @media (max-width: 500px) {
      width: 50%;
      margin: 5px;
      margin-top: -15px;
      margin-bottom: -15px;
      margin-left: 0px;
      margin-right: 0px;
      height: 220px;
    }

    .title {
      max-height: 150px;
      font-size: 17px;

      @media (max-width: 500px) {
        font-size: 16px;
      }
    }

    .raiting {
      opacity: 1;
    }
  }

  .title {
    display: block;
    background: rgba(0, 0, 0, 0.5098039216);
    padding: 5px 10px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    font-size: 15px;
    box-shadow: 0 0 1px 1px rgba(79, 79, 79, 0.5607843137);
    transition: 0.3s;
    overflow: hidden;
    max-height: 45px;
  }
}

.next_video_date {
  font-size: 14px;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black;
  max-height: 0;
  padding: 0 5px;
  opacity: 0;
  transition: 0.3s;
}

.last_added_video,
.raiting {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  padding: 5px 10px;
  font-size: 14px;
  background: #ddd;
  border-radius: 15px 0 10px 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #00000082;
  box-shadow: 0 0 10px black;

  .feather {
    width: 16px;
    height: 16px;
  }

  &.right {
    border-radius: 0 15px 0 10px;
    color: black;
    right: 0;
    left: auto;
    background: #ffffff82;
  }
}

.raiting {
  opacity: 0;
  transition: .3s;
  top: 28px;
  gap: 5px;
  right: 0;
  left: auto;
  box-shadow: 0 5px 5px #00000063;
  border-radius: 10px 0 0 10px;

  &.gold {
    background: #ffb60073;
  }

  &.silver {
    background: rgba(162, 162, 162, 0.548);
  }

  &.bronz {
    background: rgb(69 29 0 / 73%);
  }
}

.last_added_video.new {
  background: rgb(12 113 33 / 51%);
}

.last_watch_video {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  font-size: 25px;
  background: #ddd;
  border-radius: 0 15px 10px 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #00000082;
  box-shadow: 0 0 10px black;
}
</style>
