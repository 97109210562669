<template>
  <div v-if="auth">
    <div class="preloader" v-if="preloader">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
    <header-side :user="user" />
    <router-view v-if="!loading" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import HeaderSide from "@/components/HeaderSide.vue";

export default {
  components: { HeaderSide },
  data() {
    return {
      avatar: "/images/avatar.png",
      counter: 0,
      setTimecodeInterval: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["user", "preloader", "timeCodesServer"]),
    auth() {
      return this.user;
    },
  },
  methods: {
    ...mapActions(["login", "getAuth", "fetchFilms"]),
    ...mapMutations(["setTimeCodes"]),
    // getTimeCodes() {
    //   const loc = localStorage;
    //   let timeCodes = [];
    //   for (var key in loc) {
    //     if (loc.hasOwnProperty(key) && ~key.indexOf("tc-")) {
    //       timeCodes.push({ key, value: loc[key] });
    //     }
    //   }
    //   return timeCodes;
    // },
    // async update() {
    //   const timeCodeLocal = this.getTimeCodes();
    //   if (timeCodeLocal?.length !== this.timeCodesServer?.length) {
    //     const merg = this.timeCodesServer.concat(timeCodeLocal);

    //     if (merg.length == 0) return;

    //     this.setTimeCodes(merg);
    //     await this.updateTimeCodes(timeCodeLocal);
    //   } else if (!this.timeCodesServer?.length) {
    //     await this.updateTimeCodes(timeCodeLocal);
    //   } else {
    //     const update = this.timeCodesServer
    //       .map((l) => timeCodeLocal.find((s) => s.key == l.key && s.value != l.value))
    //       .filter((e) => !!e);

    //     if (update.length == 0) return;

    //     this.setTimeCodes(update);
    //     await this.updateTimeCodes(timeCodeLocal);
    //   }
    // },
    async start() {
      this.loading = true;
      // setInterval(async () => {
      //   await this.update();
      // }, 15000);
      // await this.fetchTimeCodes();
      // await this.update();
      this.loading = false;
    },
  },
  async mounted() {
    if (!this.user) {
      let nick = prompt("Твой ник?");
      if (nick) {
        await this.login(nick);
        await this.start();
        // await this.fetchFilms()
      }
    } else {
      await this.start();
    }
  },
};
</script>

<style lang="scss">
a {
  text-decoration: none;
  color: #484848;
}

body {
  color: #2c3e50;
}

* {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  white-space: pre-line;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 1px;
  height: 1px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 1px solid #6d5ed396;
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background: #6d5ed396;
  border: 1px none #6d5ed396;
  border-radius: 50px;
}

.bold {
  font-weight: bold;
}

.bg {
  background: url("~/public/images/logo.png") 50% 50% / cover;
  height: 200px;
  position: absolute;
  width: 100%;

  .title_wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(6px);
    background: #0000003b;
    color: #fff;
    font-size: 22px;
    padding: 10px 15px;
    text-shadow: 0 0 2px black;
  }

  .title {
    max-width: 1360px;
    margin: auto;
    height: 55px;
    display: flex;
    align-items: center;
  }
}

.page {
  position: relative;
  z-index: 1;

  .title_wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 22px;
    padding: 10px 15px;
    text-shadow: 0 0 2px black;
  }

  .title {
    max-width: 1360px;
    margin: auto;
    height: 55px;
    display: flex;
    align-items: center;
  }
}

.content {
  position: relative;
  top: 75px;
  max-width: 1360px;
  margin: auto;
  border-radius: 10px;
  background: #f7f6fb;
  padding: 20px;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.preloader {
  position: fixed;
  background: #00000070;
  width: 100vw;
  bottom: 0;
  top: 0;
  z-index: 10;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #cef;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active до версии 2.1.8 */
  {
  opacity: 0;
}

input {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-weight: 500;
}

.button {
  padding: 11px 15px;
  margin: 0 10px;
  padding: 11px 15px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.27);
  transition: 0.3s;
  color: #6d5ed3;
  border: none;
  cursor: pointer;
  font-weight: 600;
  display: inline-flex;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.37);
    background: #6d5ed3;
    color: #fff;
  }

}

.flex {
  display: flex;
}

.m-t-15 {
  margin-top: 15px;
}

.pointer {
  cursor: pointer;
}

.hover {
  transition: .3s;
  transform: scale(1);

  &:hover {
    transform: scale(1.1);
  }
}
</style>
