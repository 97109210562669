<template>
  <div>
    <div class="bg" :style="`background-image: url(/images/bg.jpg)`">
      <div class="title_wrap"></div>
    </div>
    <div class="page">
      <div class="title_wrap">
        <div class="title"><span style="display: flex;">Твой набор тайтлов</span>
          <div class="search">
            <input class="search" type="text" placeholder="Поиск" v-model="search" @input="searchReal()" />
            <div class="search-res" v-if="search">
              <div class="search-res-wrap" @click="clearSearchShort()"></div>
              <div v-for="s in searchShortRes" :key="s.url" @click="toGoSearchItem(s.url)" class="search-res-item">
                {{ s.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div>
        <h3>Новые аниме</h3>
      </div>
      <div class="watch-content recomend">
        <transition mode="out-in" name="fade" appear>
          <cards v-if="newFilms" :filmsSort="newFilms_sort" :newVideo="true" />
        </transition>
      </div>
      <h3 class="flex m-t-15">Твои аниме
        <Icon class="pointer hover" @click="sort = !sort" :name="`bar-chart`" />
      </h3>
      <transition mode="out-in" name="fade" appear>
        <cards v-if="filmsSort" :filmsSort="filmsSort" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Cards from "@/components/Cards.vue";
import Icon from "@/components/Icon.vue";

export default {
  name: "HomeView",
  data() {
    return {
      search: "",
      sort: false,
      setTimeout: ""
    }
  },
  components: {
    Cards,
    Icon,
  },
  computed: {
    ...mapGetters(["films", "newFilms", "searchShort"]),
    newFilms_sort() {
      let films = this.newFilms.filter(e => e.last_added_video > 0);
      return films.filter((e, i) => i < 6)
    },
    searchShortRes() {
      return this.searchShort
    },
    filmsSort() {
      switch (this.sort) {
        case false:
          return this.films.sort((a, b) => {
            const reg = (value = "") => value.split('-')[1] || value;
            const bb = reg(b?.last_added_video?.toString()) - reg(b?.last_watch_video?.toString());
            const aa = reg(a?.last_added_video?.toString()) - reg(a?.last_watch_video?.toString());
            return bb - aa;
          })

        default:
          console.log(this.films);

          return this.films.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          })
      }
    },
  },
  methods: {
    ...mapActions(['fetchFilms', 'fetchNewFilms', "fetchSearchShort", "addFilm"]),
    ...mapMutations(['setSearchShort']),
    async searchReal() {
      clearTimeout(this.setTimeout)
      this.setTimeout = setTimeout(async () => {
        this.search.length > 3 ? await this.fetchSearchShort(this.search) : "";
      }, 700);

    },
    async toGoSearchItem(url) {
      if (url == "#") return;
      let res = await this.addFilm({ id: url })
      let id_film = res.id
      this.clearSearchShort()
      this.$router.push(`/watch/${id_film}/1`)
    },
    clearSearchShort() {
      this.search = "";
      this.setSearchShort([])
    }
  },
  async mounted() {
    await this.fetchNewFilms(15);
    await this.fetchFilms();
  },
};
</script>

<style lang="scss">
.content {
  @media (max-width: 1023px) {
    padding: 5px;
  }
}

.search {
  transition: .3s;
  position: relative;

  &-res {
    position: absolute;
    z-index: 1;
    background: #fff;
    border-radius: 10px;
    padding: 0px 0;
    font-size: 15px;
    color: black;
    text-shadow: none;
    box-shadow: 0 10px 7px rgba(0, 0, 0, 0.3);
    width: 300px;
    right: 0;
    overflow: hidden;

    &-wrap {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }

    &-item {
      padding: 10px;
      border-bottom: 1px solid #ddd;
      font-weight: 500;

      &:hover {
        background: #e9e9e9;
        cursor: pointer;
      }
    }
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
